import React from "react";

import "./footer.scss";


const Footer = () => {
  return (
    <footer
      className="footer bgImg bgImgFixed"
      style={{ backgroundColor: `var(--white)` }}
    >
      <div className="container" style={{height: 20, paddingTop: 70, paddingBottom: 70}}>
        <p className="license">Copyright 2023 &#169; Gasttätte zum Aabachtal</p>
      </div>
    </footer>
  );
};

export default Footer;
