import React from "react";

import "./impressum.scss";

const Impressum = () => {
  return (
    <section id="impressum" className="impressum">
      <div className="container">
        <h1 className="impressumHeader">Impressum</h1>
        <div style={{ maxWidth: 500, margin: "auto", marginTop: 80, marginBottom: 80 }}>
          <div style={{ margin: 5 }}>

          <iframe title="anfahrt" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9889.463695299846!2d9.1217751!3d51.7080476!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47baffaac45da72f%3A0x53a95e66ebd483d3!2sGastst%C3%A4tte%20Zum%20Aabachtal!5e0!3m2!1sen!2sde!4v1679682036429!5m2!1sen!2sde" width="100%" height="450" style={{border: 0, marginBottom: 40}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      
            <h2 style={{ fontSize: 25, marginTop: 20 }}>Angaben gemäß § 5 TMG:</h2>
            <span className="row" style={{ fontSize: 20, marginTop: 10 }}>Peterhanwahr GbR</span>
            <span className="row" style={{ fontSize: 20 }}>Frank Peterhanwahr</span>
            <span className="row" style={{ fontSize: 20 }}>Brakeler Str. 6</span>
            <span className="row" style={{ fontSize: 20 }}>33034 Brakel-Istrup</span>

               </div>
        </div>
      </div>
    </section>
  );
};

export default Impressum;
