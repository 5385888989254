import React from "react";
import "./about.scss";
import bg from "./2017-10-05.jpg";
import bg2 from "./slide4.jpg";
import bg3 from "./slide9.jpg";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="row">
          <div className="aboutContainer">
            <div
              style={{ backgroundImage: `url(${bg})` }}
              className="aboutContainerImg bgImg"
            ></div>

            <div className="aboutContainerDetails">
              <h1>
                Buffets für jeden Anlass
              </h1>
              <p>
              Egal ob Geburtstags-, Silvester- oder Familienfeiern, nach vorangegangener Absprache liefern wir feinste Speisen als Buffet und im Sommer auch gerne mit Zubereitung live am Grill.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const About2 = () => {
  return (
    <section id="about2" className="about">
      <div className="container">
        <div className="row">
          <div className="aboutContainer">

          <div className="aboutContainerDetails aboutContainerDetails2">
              <h1>
                Großveranstaltungen
              </h1>
              <p>
              Mit unserer Expertise können wir den Cateringbedarf auf Betriebs-, Vereins- und Abschlussfeiern problemlos bedienen. Auch auf verschiedenen Events wie zum Beispiel Schützenfesten und Annentag. 
              </p>
            </div>

            <div
              style={{ backgroundImage: `url(${bg2})` }}
              className="aboutContainerImg bgImg"
            ></div>
 
          </div>
        </div>
      </div>
    </section>
  );
};

const About3 = () => {
  return (
    <section id="about3" className="about">
      <div className="container">
        <div className="row">
          <div className="aboutContainer">
            <div
              style={{ backgroundImage: `url(${bg3})` }}
              className="aboutContainerImg bgImg"
            ></div>

            <div className="aboutContainerDetails">
              <h1>
              Religiöse Anlässe
              </h1>
              <p>
              Auch den hohen Ansprüchen einer Hochzeit oder einer Trauerfeier werden wir gerecht, inklusive Gedeck. Klassische Anlässe wie Kommunion und Weihnachten zählen ebenfalls zu unseren Spezialitäten.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


export {About, About2, About3};
