import React from "react";
import { AiOutlineHome } from 'react-icons/ai';
import { GrContact } from 'react-icons/gr';


import "./header.scss";

const Header = () => {
  return (
    <header className="header">
      <nav className="navbar">
        <a href="#slide">
          <div className="logo">Gaststätte Zum Aabachtal</div>
        </a>
        <div className="menuItems">
          <a href="#slide">
            <div className="menuItem">Home</div>
          </a>
          <a href="#contact">
            <div className="menuItem">Kontakt</div>
          </a>
          <a href="#impressum">
            <div className="menuItem">Impressum</div>
          </a>
        </div>
      </nav>

      <nav className="mobileNavbar">
        <div className="mobileItems">
          <a href="#slide">
            <div className="mobileItem"><AiOutlineHome /></div>
          </a>
          <a href="#contact">
            <div className="mobileItem"><GrContact /></div>
          </a>
        </div>
      </nav>

    </header>
  );
};

export default Header;

