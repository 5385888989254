import React from "react";
import Form from "./Form/Form";

import { FaMobileAlt } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import "./contact.scss";

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div className="container">
        <h1 className="contactHeader">Kontakt</h1>
        <div className="contactAddress">
          <div className="contactAddressDetails">
            <h2><FaMobileAlt /></h2>
            <h1>Telefon</h1>
            <p><a style={{fontWeight: "bold"}} href="tel:05272 8111">05272 8111</a></p>
          </div>
          <div className="contactAddressDetails">
            <h2><AiOutlineMail /></h2>
            <h1>Email</h1>
            <p><a style={{fontWeight: "bold"}} href = "mailto: aabachtal@gmail.com">aabachtal@gmail.com</a></p>
          </div>
        </div>

        <Form />
      </div>
    </section>
  );
};

export default Contact;
