import React, { useState } from "react";
import axios from 'axios';

import "./form.scss";

const Form = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    let res = await axios.post("https://contact.zum-aabachtal.de/send", {
      "email": email,
      "name": name,
      "message": message
    });

    if (res.data.status === "fail") {
      alert("Error" + res.data.error);
    }
    else if (res.data.status === "success") {
      alert(res.data.message);
      resetForm();
    }
    console.log(res);
  }

  const resetForm = () => {
    setMessage("");
    setName("");
    setEmail("");
  }

  return (
    <form className="contactForm" onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        placeholder="Name"
        required
        value={name}
        onChange={e => setName(e.target.value)} />
      <input
        type="email"
        name="email"
        placeholder="E-Mail"
        required
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <textarea
        type="text"
        name="message"
        rows="5"
        placeholder="Nachricht"
        required
        value={message}
        onChange={e => setMessage(e.target.value)}
      ></textarea>
      <button type="submit">Nachricht senden</button>
    </form>
  );
};

export default Form;
