
import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import "./slide.scss";

const Slideshow = () => {
    const images = [
        "./assets/slide2.jpg",
        "./assets/slide3.jpg",
        "./assets/slide5.jpg",
        "./assets/slide6.jpg",
        "./assets/slide7.jpg",
        "./assets/slide8.jpg",
        "./assets/slide9.jpg",
        "./assets/slide10.jpg",
        "./assets/slide11.jpg",
    ];

    return (
        <section id="slide" className="slide">
        <div className='container'>
            <div className='roundBorder ccc' style={{overflow: "hidden"}}>
        <Slide className="slider">
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[0]}` }}>
                    
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[1]})` }}>
                    
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[2]})` }}>
                    
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[3]})` }}>
                    
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[4]})` }}>
                   
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[5]})` }}>
                   
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[6]})` }}>
                   
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[7]})` }}>
                   
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[8]})` }}>
                   
                </div>
            </div>

        </Slide>
        </div>
        </div>    
        </section>
        );
};

export default Slideshow;