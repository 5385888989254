import React from "react";
import { Route, Redirect } from "react-router-dom";

import Header from "./Header/Header";
import {About, About2, About3} from "./About/About"; 

import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import { FaArrowAltCircleUp } from "react-icons/fa";
import Slideshow from "./Slide/Slide";
import Impressum from "./Impressum/Impressum";

import "./App.scss";
import 'react-slideshow-image/dist/styles.css'

const App = () => {
  const topOfThePagehander = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  return (
    <div>
      <FaArrowAltCircleUp
        className="Top-of-the-page"
        onClick={topOfThePagehander}
      />

      <Route path="/" component={Slideshow} />
      <Route path="/" component={Header} />

      <Route path="/" component={About} />
      <Route path="/" component={About2} />
      <Route path="/" component={About3} />

      <Route path="/" component={Contact} />
      <Route path="/" component={Impressum} />
      <Route path="/" component={Footer} />
      <Redirect to="/" />
    </div>
  );
};

export default App;
